<template>
  <v-container :loading="loading">
    <v-card :loading="loading">
      <v-card-title> <v-icon class="mr-12" size="40">mdi-poll</v-icon> Statistiques</v-card-title>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
          <v-row>
            <v-card elevation="0" class="col-md-3" >
              <v-card-title><v-icon class="mr-12">mdi-human-male-child</v-icon> {{ nbStudents }} {{ $t("students") }}</v-card-title>
              <v-card-subtitle>Élèves</v-card-subtitle>
            </v-card>
            <v-card elevation="0" class="col-md-3" >
              <v-card-title><v-icon class="mr-12">mdi-human-male</v-icon> {{ nbTutors }} {{ $t("tutors") }}</v-card-title>
              <v-card-subtitle>Tuteurs</v-card-subtitle>
            </v-card>
            <v-card elevation="0" class="col-md-3" >
              <v-card-title><v-icon class="mr-12">mdi-school</v-icon> {{ nbTeachers }} {{ $t("teachers") }}</v-card-title>
              <v-card-subtitle>{{ $t("teachers") }}</v-card-subtitle>
            </v-card>
            <v-card elevation="0" class="col-md-3" >
              <v-card-title><v-icon class="mr-12">mdi-folder</v-icon> {{ nbInscriptions }} {{ $t("inscriptions") }}</v-card-title>
              <v-card-subtitle>{{ $t("inscriptions") }}</v-card-subtitle>
            </v-card>
          </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title> <v-icon class="mr-12" size="40">mdi-finance</v-icon> Finances</v-card-title>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
          <v-row>
            <v-card elevation="0" class="col-md-4" >
              <v-card-title><v-icon class="mr-12" >mdi-credit-card-check</v-icon> {{ amount_recu }} €</v-card-title>
              <v-card-subtitle>Montant reçus</v-card-subtitle>
            </v-card>
            <v-card elevation="0" class="col-md-4" >
              <v-card-title><v-icon class="mr-12">mdi-credit-card-clock</v-icon> {{ amount_restant }} €</v-card-title>
              <v-card-subtitle>Montant restant</v-card-subtitle>
            </v-card>
            <v-card elevation="0" class="col-md-4" >
              <v-card-title><v-icon class="mr-12">mdi-credit-card</v-icon> {{ amount_attendu }} €</v-card-title>
              <v-card-subtitle>Montant total</v-card-subtitle>
            </v-card>
          </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data() {
    return {
      loading: false,
      nbStudents: 0,
      nbTutors: 0,
      nbTeachers: 0,
      nbInscriptions: 0,
      amount_attendu: 0,
      amount_recu: 0
    };
  },
  mounted() {
    this.initNbStudents();
    this.initNbTutors();
    this.initNbTeachers();
    this.initNbInscriptions();
    this.initReporting();
  },

  computed: {
    amount_restant() {
      return this.amount_attendu - this.amount_recu;
    },
  },

  methods: {
    initReporting() {
      this.loading = true;
      APIServices.get("/reporting/contributions")
        .then(response => {
          this.amount_recu = response.data[0].amount_recu;
          this.amount_attendu = response.data[0].amount_attendu;
          this.loading = false;
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    initNbStudents() {
      this.loading = true;
      APIServices.get("/students")
        .then(response => {
          this.nbStudents = response.data.total;
          this.loading = false;
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    initNbTutors() {
      this.loading = true;
      APIServices.get("/tutors")
        .then(response => {
          this.nbTutors = response.data.total;
          this.loading = false;
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    initNbTeachers() {
      this.loading = true;
      APIServices.get("/teachers")
        .then(response => {
          this.nbTeachers = response.data.total;
          this.loading = false;
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    initNbInscriptions() {
      this.loading = true;
      APIServices.get("/inscriptions")
        .then(response => {
          this.nbInscriptions = response.data.total;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },
  }
};
</script>

<style></style>
